@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px !important;
}
.navbar{
  background-color: #17556A  !important;
}

.nav-link{
  color: white !important;

}

.navbar-brand{
  color: white !important;
  
  
}

.nav-link.disabled {
  color: #17556A !important;
  background-color: white;  
}

.small-img {
  height: 200px;
  width: 100% !important;
  border-radius: 5px !important;

}

h1{
  font-size: 20px !important;
}

.btn{
  background-color: #1f7491  !important ;
  color: white !important;
  
  transition:  0.2s;
  box-shadow: none !important;

}

.btn:hover{
  background-color: #A8A390 !important;
  color: #1f7491 !important;  

}

/* input , .ant-picker {
  box-shadow: none !important;
  border: 2px solid black !important;
  width: 100% !important;
  border-radius: 3px !important;
} */

.ant-picker-input input{
  border:none !important;
}

.ant-tabs-ink-bar .ant-tabs-ink-bar-animated{
  background-color:black !important;
}
.ant-picker-input input{
  border:none !important;
}
/* .bs:hover{
  
  transition: 0.9s !important;
  transition-delay: 0.2s !important;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  
} */

.bs{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin-top: 20px !important;
  border-radius: 9px !important;
  padding: 20px !important;
}

.bigimg{
  height: 200px;
  border-radius: 5px !important;


}

.form-control, .ant-picker{
  box-shadow: none !important;
  margin-top: 20px;
  border: 1px solid black !important;
}

h2{
  font-size: 30px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.dropdown-toggle{
  border: 0px solid !important;
}

.fa-custom-width{
  width: 22px;
  text-align: center;
}



/* .tickets{
  display: block;
  margin: 20px 0px;
  border: 1px solid lightblue;
} */

.landing{
  /* background-color: black ;  */
  height: 100vh;
  padding-top: 130px;
}


.calendar {
  background-color: #0000FF;  /* Blue background */
}

.date-tile {
  color: #FFFFFF;  /* White text color */
}

.selected-date {
  box-shadow: 0 0 10px 5px #0000FF;  /* Blue glow */
}

.calendar {
  box-shadow: 0 0 10px 5px #000000;  /* Black box shadow */
}

.mt-3{
  background-color: #17556A;
  border-radius: 10px;
}

